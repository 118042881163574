


















































/* eslint-disable */
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import mapboxgl, { Map, Marker, MarkerOptions, Popup } from "maplibre-gl";
// @ts-ignore
import { genevaCoords } from '../lib/geoUtils';
// @ts-ignore

import { CMS } from "@/models";

@Component
export default class MapLibreEventLocationMarker extends Vue {

    @Prop() private eventLocation!: CMS.EventLocation;
    @Prop({default: () => genevaCoords}) private coordinates!: mapboxgl.LngLatLike;

    // @InjectReactive() map!: Map;
    @Prop() map!: Map;

    @Prop({default:0}) id!: number;
    @Prop({default: true}) clickable!: boolean;
    // export let isLast = false;
    // export let isCurrent = false;
    // export let showRadius = false;
    // export let radiusMeters = 35;

    zIndex = 1;
    marker!: Marker;

    get isDefault(): boolean{
        return this.eventLocation.type == "Standard";
    }
    get isPu(): boolean{
        return this.eventLocation.type == "Parcours Urbain";
    }

    get markerUrl(): string{
        if(this.eventLocation.type == "Standard"){
            return "/map_assets/SpotMarker.svg";
        }else{
            return "/map_assets/SpotMarkerPU.svg";
        }
    }

    @Watch('map')
    onMapChanged(val: Map, oldVal: Map) {
        // console.log("onmap changed", val, oldVal);
        // console.log("map = ", this.map);
        // console.log(this.coordinates);

        // unhide marker
        (this.$refs.markerElement as HTMLElement).style.opacity = "1";

        const options: MarkerOptions = {
            element: this.$refs.markerElement as HTMLElement,
            anchor: 'bottom',
        };

        this.marker = new mapboxgl.Marker(options)
            .setLngLat(this.coordinates)
            .addTo(this.map);
    }

    onClick(e: any){
        if(!this.clickable) return;
        this.$emit("selectionRequest", this.eventLocation);
    }

    beforeDestroy() {
        if(this.marker){
            // console.log("removing marker with id", id);
            this.marker.remove();
        }
    }


}
